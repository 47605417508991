export const SPECIALTY = [
  { id: "Medical Student", value: "Medical Student" },
  { id: "Allergy and Immunology", value: "Allergy and Immunology" },
  { id: "Anesthesiology", value: "Anesthesiology" },
  { id: "Breast Surgery", value: "Breast Surgery" },
  { id: "Body IR", value: "Body IR" },
  { id: "Cardiothoracic surgery", value: "Cardiothoracic surgery" },
  { id: "Colorectal Surgery", value: "Colorectal Surgery" },
  { id: "Dermatology", value: "Dermatology" },
  { id: "Emergency Medicine", value: "Emergency Medicine" },
  { id: "ENT", value: "ENT" },
  { id: "Endocrine Surgery", value: "Endocrine Surgery" },
  { id: "Family Medicine", value: "Family Medicine" },
  { id: "General surgery", value: "General surgery" },
  { id: "Hepatobiliary Surgery", value: "Hepatobiliary Surgery" },
  { id: "Internal Medicine", value: "Internal Medicine" },
  { id: "Neurology", value: "Neurology" },
  { id: "Neurosurgery", value: "Neurosurgery" },
  { id: "Neuro IR", value: "Neuro IR" },
  { id: "OB/GYN", value: "OB/GYN" },
  { id: "Ophthalmology", value: "Ophthalmology" },
  { id: "Orthopedic Surgery", value: "Orthopedic Surgery" },
  { id: "Oral and Maxillofacial Surgery", value: "Oral and Maxillofacial Surgery" },
  { id: "Plastic Surgery", value: "Plastic Surgery" },
  { id: "PM&R", value: "PM&R" },
  { id: "Radiology", value: "Radiology" },
  { id: "Surgical oncology", value: "Surgical oncology" },
  { id: "Transplant Surgery", value: "Transplant Surgery" },
  { id: "Trauma surgery", value: "Trauma surgery" },
  { id: "Vascular surgery", value: "Vascular surgery" },
  { id: "Vascular IR", value: "Vascular IR" },
  { id: "Urology", value: "Urology" },
];

export const TRAINING_STATUS = [
  { id: "PGY-1", value: "PGY-1" },
  { id: "PGY-2", value: "PGY-2" },
  { id: "PGY-3", value: "PGY-3" },
  { id: "PGY-4", value: "PGY-4" },
  { id: "PGY-5", value: "PGY-5" },
  { id: "PGY-6", value: "PGY-6" },
  { id: "PGY-7", value: "PGY-7" },
  { id: "Fellow Year 1", value: "Fellow Year 1" },
  { id: "Fellow Year 2", value: "Fellow Year 2" },
  { id: "Fellow Year 3", value: "Fellow Year 3" },
  { id: "Fellow Year 4", value: "Fellow Year 4" },
  { id: "Training Completed (Attending/Staff)", value: "Training Completed (Attending/Staff)" },
];

export const MED_STUDENT_TRAINING_STATUS = [
  { id: "MS1", value: "MS1" },
  { id: "MS2", value: "MS2" },
  { id: "MS3", value: "MS3" },
  { id: "MS4", value: "MS4" },
  { id: "MS5", value: "MS5" },
  { id: "MS6", value: "MS6" },
];

export const modalityList = ["CT", "Fluoroscopy", "MRI", "Nuclear Medicine", "PET/CT", "PET/MR", "Radiograph", "SPECT-CT", "Ultrasound"];

export const prepClassification = [
  {
    value: "Trauma",
    label: "Trauma",
  },
  {
    value: "Non-Trauma",
    label: "Non-Trauma",
  },
];
